import jwtDecode from "jwt-decode"
import { NextApiRequestCookies } from "next/dist/server/api-utils"
import { IncomingMessage, ServerResponse } from "http"
import { fetchRefreshToken } from "@/api/authAPI"
import { fetchBindUserCart } from "@/api/cartAPI"
import {
  getClientUserStorage,
  getServerUser,
  setUserStorage,
} from "@/hooks/auth/helpers"
import { User } from "@/hooks/auth/types"
import { getCartTokenStorage, setCartTokenStorage } from "@/hooks/cart/helpers"
import {
  BASE_URL,
  BASE_VERSION_URL,
  MESSAGE_ERROR_DEFAULT,
} from "@/utils/constants"
import { ApiError } from "../../contracts"

type RequestOptionsType = RequestInit

export type FetcherBasePropsType = {
  server?: boolean
  req?: IncomingMessage & { cookies: NextApiRequestCookies }
  res?: ServerResponse
}

const normalizeUrl = (url: string) =>
  url.includes("http") ? url : getAbsolutePath(true) + url

export const getAbsolutePath = (isServer?: boolean): string =>
  isServer ? `${BASE_URL}${BASE_VERSION_URL}` : ""

const getUser = ({
  req,
}: {
  req?: IncomingMessage & { cookies: NextApiRequestCookies }
}) => {
  let user
  if (req !== undefined) {
    user = getServerUser({ req })
  } else {
    user = getClientUserStorage()
  }

  return user
}

const updateToken = async ({
  res,
  accessToken,
  refreshToken,
  user,
}: {
  res?: ServerResponse
  accessToken: string
  refreshToken: string
  user: User
}) => {
  const tokenDecode:
    | {
        user?: string
        type?: string
        exp?: number
      }
    | undefined = jwtDecode(accessToken)
  if (!!tokenDecode?.exp) {
    if (Date.now() + 1000000 >= tokenDecode.exp * 1000) {
      const newToken = await fetchRefreshToken({
        refresh_token: refreshToken,
      }).catch(() => {
        setUserStorage(null, res)
        setCartTokenStorage(null, res)

        if (res !== undefined) {
          res.setHeader("location", `/`)
          res.statusCode = 302
        } else {
          window?.location.replace("/")
        }
      })

      if (newToken) {
        setUserStorage(
          {
            ...user,
            cart: newToken.info?.cart || null,
            accessToken: newToken.access_token || null,
            refreshToken: newToken.refresh_token || null,
          },
          res,
        )
        setCartTokenStorage(newToken.info?.cart || null, res)
      }
    }
  }
}

const getHeadersWithAuth = async <T>(
  requestOptions: T,
  isAuth?: boolean,
  req?: IncomingMessage & { cookies: NextApiRequestCookies },
  res?: ServerResponse,
): Promise<T> => {
  if (!isAuth) {
    return requestOptions
  }

  const user = getUser({
    req: req,
  })

  if (
    user === null ||
    user.accessToken === null ||
    user.refreshToken === null
  ) {
    return requestOptions
  }

  if (user.accessToken) {
    await updateToken({
      res: res,
      user: user,
      refreshToken: user.refreshToken,
      accessToken: user.accessToken,
    })
    requestOptions["headers"]["Authorization"] = user.accessToken
  }

  return requestOptions
}

export const get = async <R>(
  url: string,
  isAuth?: boolean,
  req?: IncomingMessage & { cookies: NextApiRequestCookies },
  res?: ServerResponse,
  withNormalizeUrl = true,
): Promise<R> => {
  const requestOptions: RequestOptionsType = {
    method: "GET",
    headers: {},
  }
  await getHeadersWithAuth(requestOptions, isAuth, req, res)

  return fetch(withNormalizeUrl ? normalizeUrl(url) : url, requestOptions).then(
    (response) => handleResponse(response, undefined, req, res),
  )
}

export const post = async <R, Q>(
  url: string,
  body: Q,
  isAuth?: boolean,
  key?: string,
  req?: IncomingMessage & { cookies: NextApiRequestCookies },
  res?: ServerResponse,
  withNormalizeUrl = true,
): Promise<R> => {
  const requestOptions: RequestOptionsType = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  }
  await getHeadersWithAuth(requestOptions, isAuth, req, res)

  return fetch(withNormalizeUrl ? normalizeUrl(url) : url, requestOptions).then(
    (response) => handleResponse(response, key, req, res),
  )
}

export const put = async <R, Q>(
  url: string,
  body: Q,
  isAuth?: boolean,
): Promise<R> => {
  const requestOptions: RequestOptionsType = {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  }
  await getHeadersWithAuth(requestOptions, isAuth)

  return fetch(normalizeUrl(url), requestOptions).then((response) =>
    handleResponse(response),
  )
}

export const deleteFetch = async <R, Q>(
  url: string,
  body: Q,
  isAuth?: boolean,
  key?: string,
): Promise<R> => {
  const requestOptions: RequestOptionsType = {
    mode: "cors",
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  }
  await getHeadersWithAuth(requestOptions, isAuth)

  return fetch(normalizeUrl(url), requestOptions).then((response) =>
    handleResponse(response, key),
  )
}

const handleResponse = async <T>(
  response: Response,
  key?: string,
  req?: IncomingMessage & { cookies: NextApiRequestCookies },
  res?: ServerResponse,
): Promise<T> => {
  const status = response.status

  if (status === 204) {
    return null as unknown as T
  }

  if (status === 401) {
    const user = getUser({
      req: req,
    })

    if (user !== null) {
      void updateToken({
        res: res,
        user: user,
        refreshToken: user.refreshToken || "",
        accessToken: user.accessToken || "",
      })
    }
  }

  if (response.status === 418) {
    const res = await response.json()
    throw {
      status: 418,
      message: res?.message || MESSAGE_ERROR_DEFAULT,
    } as ApiError
  }

  if (!response.ok) {
    const res = (await response.json()) as {
      code: string
      message: string
    }

    if (res.code === "GR_000016" /* не найдена корзина */) {
      const cart = getCartTokenStorage()

      if (cart) {
        void fetchBindUserCart({ cart })
      }
    }

    console.log("error ", res?.message)

    throw {
      ...res,
      status: response.status,
      message: res?.message || MESSAGE_ERROR_DEFAULT,
    } as ApiError
  }

  return (await response.json()) as T
}
