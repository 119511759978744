import { AnyAction, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { isEqual } from "lodash"
import { HYDRATE } from "next-redux-wrapper"
import { BreadcrumbItemType } from "@/components/Breadcrumbs/BreadcrumbItem"
import { ProductType } from "@/components/Products/types"
import {
  INITIAL_IS_ENABLED,
  INITIAL_IS_FAST,
  INITIAL_PAGE,
  INITIAL_PER_PAGE,
  TogglePageMethod,
} from "@/hooks/filterCatalog/constants"
import {
  createFiltersChecked,
  getCatalogPath,
  getStringToBoolean,
} from "@/hooks/filterCatalog/helpers"
import {
  CatalogRequestType,
  CatalogResponseCategoryType,
  CatalogResponseType,
  CategoriesStateType,
  FilterType,
  ICategoryTreeItem,
  PriceRangeItemType,
  SequenceItemType,
  SetCatalogPayloadType,
  SortByCatalogAlias,
  SortByType,
  TagType,
} from "@/types"
import {
  isParentCategory,
  normalizeCategoriesByAreas,
} from "@/utils/categories/helpers"
import {
  compareSortTypesWithIcon,
  getSubItemsBreadcrumbs,
} from "@/utils/common/helpers"
import { CategoryByAreaResponse, SortTypeResponse } from "../../../contracts"
import { RootStateType } from "../store"

export type StatePriceRangeType = {
  initial: PriceRangeItemType
  checked: PriceRangeItemType
  isSelected: boolean
}

export type ViewPageProductsType = "grid" | "list"

const initialState = {
  categoriesByAreas: [] as ICategoryTreeItem[],
  currentCategory: null as CatalogResponseCategoryType | null,
  currentTag: null as TagType | null,
  breadcrumbs: null as Record<string, BreadcrumbItemType> | null,
  products: null as ProductType[] | null,
  total: 0 as number,
  filter: {
    isLoading: false as boolean,
    filters: {} as Record<string, FilterType>,
    filtersChecked: {} as Record<string, string[]>,
    priceRange: {
      initial: {
        min: 0,
        max: 0,
      },
      // текущая, выбранная пользователем
      // или пришедшая из запроса фильтрованного каталога
      checked: {
        min: 0,
        max: 0,
      },
      isSelected: false,
    } as StatePriceRangeType,
    minQuantity: {
      initial: null as number | null,
      selected: null as number | null,
    },
  },
  isEnabled: INITIAL_IS_ENABLED as boolean,
  isFast: INITIAL_IS_FAST as boolean,
  sortBy: null as SortByType,
  sortBySelected: null as SortByCatalogAlias | null,
  page: INITIAL_PAGE as number,
  itemsPerPage: INITIAL_PER_PAGE as number,
  isLoading: false,
  togglePageMethod: TogglePageMethod.SWITCH as TogglePageMethod,
  viewProducts: "grid" as ViewPageProductsType,
  popup: {
    isShow: false as boolean,
  },
}

export const catalogSlice = createSlice({
  name: "catalog",
  initialState: initialState,
  reducers: {
    setCurrentCategory(
      state,
      action: PayloadAction<CatalogResponseCategoryType>,
    ) {
      state.currentCategory = action.payload
    },
    setSortBy(state, action: PayloadAction<SortTypeResponse>) {
      state.sortBy = compareSortTypesWithIcon(action.payload)
    },
    setTogglePageMethod(state, action: PayloadAction<TogglePageMethod>) {
      state.togglePageMethod = action.payload
    },
    setCategoriesByAreas(
      state,
      {
        payload: { categoryByAreas, categoriesFetched },
      }: PayloadAction<{
        categoryByAreas: CategoryByAreaResponse | undefined
        categoriesFetched: CategoriesStateType["fetched"]
      }>,
    ) {
      state.categoriesByAreas =
        !!categoryByAreas && !!categoriesFetched
          ? normalizeCategoriesByAreas(categoryByAreas, categoriesFetched)
          : []
    },
    updateBreadcrumbs(
      state,
      {
        payload: { category, categories },
      }: PayloadAction<{
        category: Pick<
          CatalogResponseCategoryType,
          "parent" | "uuid" | "alias" | "name" | "id" | "name_breadcrumbs"
        >
        categories: CategoriesStateType | null
        prevCategoryId?: number
      }>,
    ) {
      if (!categories) {
        state.breadcrumbs = null
        return
      }

      if (!categories.fetched || !categories.treeCompared) {
        state.breadcrumbs = null
        return
      }

      const breadcrumbs = {} as Record<string, BreadcrumbItemType>

      const {
        uuid = "",
        alias = "",
        name = "",
        parent = [],
        name_breadcrumbs = "",
      } = category || {}

      const treeComparedSlice = categories.treeCompared[uuid]

      if (!treeComparedSlice) {
        state.breadcrumbs = null
        return
      }

      const sequenceParent: SequenceItemType[] =
        treeComparedSlice[Object.keys(treeComparedSlice)[0] || ""] || []

      let treeSlice = null as ICategoryTreeItem | null

      if (sequenceParent.length > 0) {
        for (const item of sequenceParent) {
          const foundCategory = categories.fetched[item.uuid || ""]

          if (treeSlice === null) {
            treeSlice =
              categories.treeSorted.find((t) => t.uuid === item.uuid) || null
          } else {
            treeSlice = (treeSlice?.children || {})[item.uuid || ""] || null
          }

          const {
            uuid: fcUuid = "",
            name: fcName = "",
            alias: fcAlias = "",
            product_qty: fcProdQty = 0,
            name_breadcrumbs: fcNameBreadcrumbs = "",
          } = foundCategory

          breadcrumbs[fcUuid] = {
            path: getCatalogPath(fcAlias),
            title: fcNameBreadcrumbs || fcName || "",
            subItems: getSubItemsBreadcrumbs(treeSlice),
            qty: fcProdQty,
          }
        }
      }

      if (isParentCategory({ parent })) {
        treeSlice =
          !!categories.treeSorted && !!uuid
            ? categories.treeSorted.find((t) => t.uuid === uuid) || null
            : null
      } else {
        treeSlice = (treeSlice?.children || {})[uuid] || null
      }

      breadcrumbs[uuid] = {
        path: getCatalogPath(alias ?? undefined),
        title: name_breadcrumbs || name || "",
        subItems: getSubItemsBreadcrumbs(treeSlice),
      }

      state.breadcrumbs = breadcrumbs
    },
    setPopupIsShow: (state, { payload }: PayloadAction<boolean>) => {
      state.popup.isShow = payload
    },
    setViewProducts: (
      state,
      { payload }: PayloadAction<ViewPageProductsType>,
    ) => {
      state.viewProducts = payload
    },
    setFilters: (
      state,
      {
        payload,
      }: PayloadAction<
        Pick<CatalogResponseType, "filters"> & {
          extendsFilters?: Record<string, FilterType>
          requestOptions: Required<CatalogRequestType>
        }
      >,
    ) => {
      const {
        filters: categoryFilters,
        extendsFilters,
        requestOptions,
      } = payload

      const { params, store } = requestOptions

      const filters = { ...(categoryFilters || {}), ...(extendsFilters || {}) }
      const checked = createFiltersChecked({
        filters,
        queryParams: [params, store]
          .join(",")
          .split(",")
          .filter((i) => !!i),
      })
      state.filter.filters = filters
      state.filter.filtersChecked = checked
    },
    setCatalog: (state, { payload }: PayloadAction<SetCatalogPayloadType>) => {
      const { category, requestOptions } = payload

      const { page, per_page, is_fast, is_enabled, sortby, min_quantity, tag } =
        requestOptions

      const tagsCategory = category?.category?.tags || []
      const foundedTag =
        !!tag && tagsCategory.length > 0
          ? tagsCategory.find((t) => (t.seo_url || "").includes(tag))
          : undefined

      state.currentTag = foundedTag || null
      state.currentCategory = category.category || null
      state.total = category.total || 0
      state.page = +page
      state.itemsPerPage = +per_page

      state.products =
        state.togglePageMethod === TogglePageMethod.ADDITIONAL
          ? [...(state.products || []), ...(category.products || [])]
          : category.products || []

      state.togglePageMethod = TogglePageMethod.SWITCH

      const minQty = !!min_quantity ? +min_quantity : null
      state.filter.minQuantity.initial = minQty
      state.filter.minQuantity.selected = minQty

      state.isEnabled = getStringToBoolean(is_enabled)
      state.isFast = getStringToBoolean(is_fast)
      state.sortBySelected = sortby as SortByCatalogAlias

      const initialPrice = {
        min: category.priceRange?.min || 0,
        max: category.priceRange?.max || 0,
      }

      const checkedPrice = {
        min: category.priceRange?.filtered_min ?? initialPrice.min,
        max: category.priceRange?.filtered_max ?? initialPrice.max,
      }

      state.filter.priceRange.isSelected = !isEqual(initialPrice, checkedPrice)
      state.filter.priceRange.initial = initialPrice
      state.filter.priceRange.checked = checkedPrice
    },
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload
    },
    setIsLoadingFilters: (state, { payload }: PayloadAction<boolean>) => {
      state.filter.isLoading = payload
    },
  },
  // Special reducer for hydrating the state. Special case for next-redux-wrapper
  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action: AnyAction) => {
      return {
        ...state,
        ...(action.payload.catalog as RootStateType["catalog"]),
        sortBy: state.sortBy,
      }
    })
  },
})

export const {
  setCategoriesByAreas,
  setCurrentCategory,

  setTogglePageMethod,

  setIsLoading,

  setSortBy,

  setPopupIsShow,
  setViewProducts,
  setCatalog,
  updateBreadcrumbs,

  setFilters,
  setIsLoadingFilters,
} = catalogSlice.actions
