import { FC } from "react"
import dynamic from "next/dynamic"
import { LazyHydrateContainer } from "@/hoc/LazyHydrate"
import { PromptPWA } from "components/PWA/Prompt"

const AllowCookie = dynamic(() =>
  import("@/components/AllowCookie").then((mod) => mod.AllowCookie),
)

const FooterBar = dynamic(() =>
  import("@/components/FooterBar").then((mod) => mod.FooterBar),
)

const OnlyAuthModal = dynamic(
  () =>
    import("@/layouts/Default/Header/Auth/OnlyAuthModal").then(
      ({ OnlyAuthModal }) => OnlyAuthModal,
    ),
  {
    ssr: false,
  },
)

const PopupMobile = dynamic(
  () =>
    import("@/components/Catalog/PopupMobile").then(
      ({ PopupMobile }) => PopupMobile,
    ),
  {
    ssr: false,
  },
)

const CartBindPopup = dynamic(
  () =>
    import("@/components/Cart/Bind").then(({ CartBindPopup }) => CartBindPopup),
  {
    ssr: false,
  },
)

const AuthModal = dynamic(
  () =>
    import("@/layouts/Default/Header/Auth/AuthModal").then(
      ({ AuthModal }) => AuthModal,
    ),
  {
    ssr: false,
  },
)

const BaseLayout: FC = ({ children }) => {
  return (
    <>
      {children}
      <LazyHydrateContainer whenIdle>
        <FooterBar />
      </LazyHydrateContainer>

      <LazyHydrateContainer whenIdle>
        <OnlyAuthModal />
      </LazyHydrateContainer>

      <LazyHydrateContainer whenIdle>
        <AuthModal />
      </LazyHydrateContainer>

      <LazyHydrateContainer whenIdle>
        <AllowCookie />
      </LazyHydrateContainer>

      <LazyHydrateContainer whenIdle>
        <CartBindPopup />
      </LazyHydrateContainer>

      <LazyHydrateContainer whenIdle>
        <PromptPWA />
      </LazyHydrateContainer>

      <LazyHydrateContainer whenIdle>
        <PopupMobile />
      </LazyHydrateContainer>
    </>
  )
}

export { BaseLayout }
